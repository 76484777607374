<template>
  <div>
    <van-cell-group title="会议信息">
      <van-cell title="会议主题" :value="model.TITLE" />
      <van-cell title="会议地点" :value="model.LOC" />
      <van-cell title="开会时间" :value="model.TIME | datetime('YYYY-MM-DD HH:mm')" />
      <van-cell title="主持人" :value="model.HOST" />
    </van-cell-group>
    <van-cell-group title="上传照片">
      <div style="padding: 15px">
        <van-row>
          <van-uploader multiple v-model="fileList" :max-count="10" :after-read="afterRead" @delete="del"
            accept="image/*" />
        </van-row>
      </div>
    </van-cell-group>
    <div style="margin: 35px 15px">
      <van-button type="info" size="large" icon="revoke" block @click="$router.go(-1)">返回</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "pic",
  data() {
    return {
      model: {},
      fileList: [],
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      let self = this;
      let g = this.$route.query.guid;
      if (g !== undefined) {
        this.whale.remote.getResult({
          url: "/api/Mobile/MEET/MinfoApi/Get",
          data: { GUID: g },
          completed(m) {
            self.model = m.DATA;
            console.log(m.DATA.LST_PIC_URL)
            m.DATA.LST_PIC_URL.map((o) => {
              self.fileList.push({ url: o.Value, key: o.Key });
            });
          },
        });
      }
    },
    afterRead(f) {
      console.log("AR", f);
      let self = this;
      f.status = "uploading";
      f.message = "上传中...";
      if (Array.isArray(f)) {
        f.forEach((item) => {
          this.whale.image.compress({
            file: item.file,
            callback(cf) {
              self.whale.image.toB64({
                file: cf,
                callback(b64) {
                  self.whale.remote.getResult({
                    url: "/api/Mobile/MEET/MinfoApi/UpPicture",
                    data: {
                      GUID: self.$route.query.guid,
                      B64: b64,
                    },
                    finally() {
                      item.status = "done";
                    },
                    completed(m) {
                      item.key = m;
                      item.status = "done";
                    },
                  });
                },
              });
            },
          });
        });
      } else {
        this.whale.image.compress({
          file: f.file,
          callback(cf) {
            self.whale.image.toB64({
              file: cf,
              callback(b64) {
                self.whale.remote.getResult({
                  url: "/api/Mobile/MEET/MinfoApi/UpPicture",
                  data: {
                    GUID: self.$route.query.guid,
                    B64: b64,
                  },
                  finally() {
                    f.status = "done";
                  },
                  completed(m) {
                    f.key = m;
                    f.status = "done";
                  },
                });
              },
            });
          },
        });
      }
    },
    del(f) {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/Mobile/MEET/MinfoApi/DelPicture",
        data: {
          GUID: self.$route.query.guid,
          PIC: f.key.DATA,
        },
      });
    },
  },
};
</script>
<style scoped></style>